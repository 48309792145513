import React from 'react';
import {CartItemDataHook} from '../../CartItem';
import {ICartItem} from '../../../../../../../../types/app.types';
import s from '../../CartItem.scss';

export const Discounts = ({item}: {item: ICartItem}) => {
  return (
    <div data-hook={CartItemDataHook.Discounts}>
      {item.discountRules?.map(({name}, index: number) => (
        <div
          key={`${CartItemDataHook.DiscountName}-${index}`}
          data-hook={`${CartItemDataHook.DiscountName}-${index}`}
          className={s.discountName}>
          {name}
        </div>
      ))}
    </div>
  );
};
