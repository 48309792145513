import React from 'react';
import {CartItemDataHook} from '../../CartItem';
import {ICartItem} from '../../../../../../../../types/app.types';
import s from '../../CartItem.scss';

export const Name = ({item}: {item: ICartItem}) => {
  const {name} = item.product;

  return (
    <div data-hook={CartItemDataHook.Name} className={s.productName}>
      {name}
    </div>
  );
};
